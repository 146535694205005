.carousel-indicators {

}
.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    background-color: #75A55B;
    border-radius: 50%;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.8;
    transition: opacity .6s ease;
}

li.active {
    background-color: white;
}

.carusel-item {
    /*transition: transform 3s ease, opacity 3s ease-out;*/
    /*background: red;*/
    /*transition:  transform 2s ease, background-color 3s ease-out;*/
    transition: transform 2s ease, background-color 5s ease-in-out, transform 2s ease-out;
    /*-webkit-transform-style: preserve-3d;*/
    /*-moz-transform-style: preserve-3d;*/
    /*transform-style: preserve-3d;*/
}

.carusel-caption{
    margin-top: -10em;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
}

.carousel-caption {
    text-shadow: 2px 2px #1c1b1b;
    text-align: center;
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    /*-webkit-transform-style: preserve-3d;*/
    /*-moz-transform-style: preserve-3d;*/
    /*transform-style: preserve-3d;*/
}