@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;600&display=swap');
.root{

}
/*.modal-dialog {*/
/*    !*display:flex !important;*!*/
/*    margin: auto;*/
/*    !*align-items: center;*!*/
/*    !*width: 200vmax;*!*/
/*}*/

.titlePage {
    background-color: #F1F1F1;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 3vh;
    letter-spacing: 10px;
}

.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

.columnText{
    border-style: solid;
    border-radius: 0px;
    border-width: 0px;
    border-color: black;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left ;
    /*width: 45%;*/
    background-color: white;
    box-shadow: 3px 3px 10px #75A55B;
}

.textCenter{
   margin: 20px;
    text-align: left ;
}

.text-bottom{
    border-style: solid;
    border-radius: 0px;
    border-width: 0px;
    box-shadow: 3px 3px 10px #75A55B;
    background-color: white;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center ;
    margin-top: 1em;
    margin-bottom: 1em;
}

.text-bottom-row{
    border-color: black;
    margin-top: 1em;
}


.columnImage{
    border-radius: 0px;
    border-style: solid;
    border-width: 0px;
    border-color: black;
    /*margin: 10px;*/
    /*width: 48%;*/
    height: 28em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-color-sqr:hover{
    /*transition: background-color 1s ease;*/
    /*background-color: rgba(255,255,255,0);*/
    /*background-color: rgba(255,255,255,0.6);*/
}

.bg-image-card {
    /*width: 500px;*/
    height: 400px;
    /*background-repeat: no-repeat;*/
    /*transition: 1000ms opacity ease;*/
    /*-webkit-transition: 1000ms opacity ease;*/
}


