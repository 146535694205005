.floatButton { 
  transition: all 0.5s ease;
  z-index: 1000;
  font-size: 1.5em;
  position: fixed; 
  bottom: 2em; 
  right: 20px; 
  display: block; 
  background: black;
  color: white; 
  width: 80px;
  height: 80px; 
  border-radius: 40px;
  border-width: 0px;

  box-shadow: 0px 0px 5px #545454;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  white-space: normal; /* восстанавливаем свойству значение по умолчанию */
}

  

.floatButton:hover { 

  /* border-radius: 19px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;*/

  transition: all 1s ease; 
  
  box-shadow: 5px 5px 15px #545454;


}