@import "~react-image-gallery/styles/css/image-gallery.css";

    .list-group-item-action {
        color: black;
        align-items: flex-start;
    }

    .list-group-item-action.active {

        background-color: #495057;
        transition: background-color 1s ease-in;
        background-color: #75A55B;
        color: black;
    }

    .year-row {
        color: black;
        font-size: 1.4em;
        font-weight: bold;
        text-align: left;
    }

    .title-galeria {
        font-size: 1em;
        color: black;
        font-weight: bold;
        text-align: center;
    }

    .image-gallery-slide .image-gallery-description {
        /*background: #75a55b;*/
        bottom: 20px;
        color: #fff;
        margin: 0;
        position: absolute;
        /*top: 80%;*/
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 0%);

        /*left: auto;*/
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        /*margin: 0;*/
        /*!*line-height: 1;*!*/
        /*!*padding: 10px 20px;*!*/
        white-space: normal;
    }

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(80vh - 80px);
}

.image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain;
}