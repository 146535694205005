
.scontactuy-title{
    margin-top: 3em;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
}

.button-send-email{
    margin-top: 2em;
    border-radius: 20px;
    width: 100%;
    font-size: 1.6em;
}

.edit-send:focus{
    background-color: #75A55B;
    color: black;
}

.edit-send {
    font-size: 1.6em;
    background-color: #75A55B;
    color: black;
    margin-top: 1em;
    border-radius: 20px;
    width: 100%;

}

.input-form {
    width:70%;
    margin: auto;
    padding-bottom: 2em;
}